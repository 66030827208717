import "./solution.css";

const Heading = () => {
    return (
        <div className="solution-info">
            <p className="solution-heading">Some of our Key Solutions</p>
        </div>
    )
}

export default Heading;