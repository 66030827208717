import About from "./about";

const Aboutus = () => {
    return (
        <div className="aboutsection">
            <About />
        </div>
    )
}

export default Aboutus;